import React from "react"
import Medication from "../../../components/medication"
import { medications } from "../index"
import Layout from "../../../components/layout"

const Med = () => {
	const med = medications.find(
		({ id }) => id === "nonsteroidal-anti-inflammatory-drugs-nsaids"
	)
	return (
		<Layout>
			<Medication {...med} />
		</Layout>
	)
}

export default Med
